<template>
    <div>
        <v-text-field
            v-bind="$attrs"
            :type="type"
            :class="classes"
            :placeholder="placeholder"
            :value="value"
            :disabled="disabled"
            ref="inputbox"
            @input="onInput"
            @keypress="onKeypress"
            underlined
            :error="error"
            @keydown.ctrl.meta.space="onIOSEmojiPanelOpen($event)"
            @keydown="ignoreSystemEmojis($event)"
            @copy.prevent
            @paste.prevent
        ></v-text-field>
        <div class="input-detail">
            <div class="message-error" v-if="error">
                {{ errorMessage }}
            </div>
            <span v-if="counter" class="counter-number">{{ value.length }}/{{ counter }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'base-input',
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        placeholder: {
            type: String,
            required: false,
        },
        error: {
            type: Boolean,
            required: false,
        },
        errorMessage: {
            type: String,
            required: false,
        },
        type: {
            type: String,
            required: false,
            default: 'text',
        },
        value: {
            type: String,
            required: false,
        },
        counter: {
            type: String,
            required: false,
        },
        name: {
            type: String,
            required: false,
        },
    },
    computed: {
        classes() {
            return {
                input: true,
                'input-error': this.error,
            };
        },
    },
    mounted() {
        const inputBox = this.$refs.inputbox;
        inputBox.onpaste = (e) => e.preventDefault();
    },
    methods: {
        onInput(event) {
            this.$emit('input', event);
        },
        onKeypress(event) {
            this.$emit('onKeypress', event);
        },
        onIOSEmojiPanelOpen(e) {
            e.preventDefault();
        },
        ignoreSystemEmojis(e) {
            if (!/^[a-zA-Z0-9\s&\d\u0E00-\u0E7F]|[/\W|_/g]*$/.test(e.key)) {
                e.preventDefault();
            }
        },
    },
};
</script>

<style scoped>
.input {
    width: 100%;
    /* height: 44px;
    border: 1px solid #222222;
    background-color: #ffffff;
    border-radius: 8px; */
    font-family: Cordia New;
    font-weight: 400;
    color: #2e2e2e;
    /* padding: 0 24px; */
    /* line-height: 44px;
    text-indent: 1px; */
    /* font-size: 1.4rem; */
}
.input:focus {
    outline: none;
}
.input.input-error {
    /* border: 1px solid #f62b2f; */
}
/* .input::placeholder {
    color: #bbbbbb;
    line-height: 44px;
    text-indent: 1px;
    font-family: Cordia New !important;
} */
.input:disabled {
    border-color: #545859;
    background-color: #f0f0f0;
}
.message-error {
    color: #f72b2f;
    font-family: Cordia New;
    font-weight: 400;
    font-size: 16px !important;
    display: block;
    margin-top: 4px;
}
.counter-number {
    font-weight: 400;
    font-size: 12px !important;
    float: right;
    color: #9ea0a5;
}
</style>
