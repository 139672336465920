import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/pre-register',
    name: 'Pre-Register Page',
    component: () => import('../views/register/PreForm.vue')
  },
  {
    path: '/register',
    name: 'Register Page',
    component: () => import('../views/register/Form.vue')
  },
  {
    path: '/register/success',
    name: 'Register Success Page',
    component: () => import('../views/register/Success.vue')
  },
  {
    path: '/register/otp',
    name: 'Register OTP Page',
    component: () => import('../views/otp/Index.vue')
  },
  {
    path: '/clear-data',
    name: 'Blank Clear Date Page',
    component: () => import('../views/register/ClearData.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
